"use client";

// External imports
import React from "react";
import clsx from "clsx";
import parse from "html-react-parser";
import Image from "next/image";
import { Swiper, SwiperSlide } from 'swiper/react';

// Internal imports
import { VideoWithOptimizedPoster } from "@/components/acfFlexibleContentLayouts/VideoWithOptimizedPoster";
import { ButtonGroup } from "@/components/buttons/ButtonGroup";

// Type Imports
import { IPageHeaderSlider, ISlide } from "@/types/ACFLayouts/PageHeaderSlider";
import { IPageHeaderStatic } from "@/types/ACFLayouts/PageHeaderStatic";

type Props = {
  data: IPageHeaderSlider;
};

/**
 * This component is used to render a `page_header_slider` block in the page builder.
 * @param props
 * @returns
 */
export const PageHeaderSlider = ({ data }: Props) => {
  const [isMounted, setIsMounted] = React.useState(false);

  const classes = data.classes
    ? data.classes.map((c) => c.class).join(" ")
    : "";
  const id = data.id ? data.id : undefined;

  const StaticPageHeaderData: IPageHeaderStatic = {
    acf_fc_layout: "page_header_static",
    header_title: data.header_slides[0].slide_title,
    header_subtitle: data.header_slides[0].slide_subtitle,
    header_image: data.header_slides[0].header_image,
    header_videos: data.header_slides[0].header_videos,
    type_of_media: data.header_slides[0].type_of_media as "image" | "video",
    links: data.header_slides[0].links,
    id: data.id,
    classes: data.classes,
  };

  // Group all main layout classes here
  const combinedClasses = clsx(
    "acf-layout",
    "page-header",
    "page-header-slider",
    "slider-full-width",
    "min-h-[220px]",
    "xl:min-h-[280px]",
    "overflow-hidden",
    "relative",
    classes,
  );

  return (
    <>
      <header id={id} className={combinedClasses}>
        {/* Poster fallback with the first slide */}
        {!isMounted && data.header_slides.length > 0 && (
          <SlideContent slide={data.header_slides[0]} isPoster />
        )}

        <Swiper onInit={() => setIsMounted(true)}>
          {data.header_slides &&
            data.header_slides.map((slide, index) => (
              <SwiperSlide key={slide.slide_title}>
                <SlideContent slide={slide} />
              </SwiperSlide>
            ))}
        </Swiper>
      </header>
    </>
  );
};

/********************************   Slide Content     *********************************************/
type SlideContentProps = {
  slide: ISlide;
  isPoster?: boolean;
};

const SlideContent = ({ slide, isPoster }: SlideContentProps) => {
  return (
    <>
      <div
        className={clsx(
          "w-full h-full flex items-center",
          isPoster ? "absolute z-30" : "relative z-20",
        )}
      >
        {/* Header Image */}
        {slide.type_of_media === "image" && slide.header_image && (
          <Image
            src={`${process.env.NEXT_PUBLIC_API_URL}${slide.header_image.url}`}
            alt={slide.header_image.alt}
            width={1920}
            height={280}
            quality={60}
            className="absolute top-0 left-0 w-full h-full hero-img object-cover object-[left_85%] -z-10"
            sizes="100vw"
            priority={isPoster}
            loading={isPoster ? "eager" : "lazy"}
          />
        )}
        {/* Header Video */}
        {slide.type_of_media === "video" && slide.header_videos && (
          <div className="header-video">
            <div className="absolute w-full h-full bg-royal-blue-700 -z-10 opacity-[.275]" />
            <VideoWithOptimizedPoster
              data={slide.header_videos}
              priority={isPoster}
            />
          </div>
        )}{" "}
        {/* Header Content */}
        <div className="wrapper flex py-10 h-full min-h-inherit items-center justify-start z-5">
          <div className="content content-flow text-pn-black-base text-left max-w-[80%] min-[320px]:max-w-[60%] sm:max-w-[40%]">
            {slide.slide_title && (
              <h1 className="font-bold">{parse(slide.slide_title)}</h1>
            )}
            {slide.slide_subtitle && (
              <p className="text-xl2 leading-[30px] md:leading-[40px] font-medium md:text-xl3 xl:mb-2">
                {slide.slide_subtitle}
              </p>
            )}

            {slide.links && <ButtonGroup links={slide.links} />}
          </div>
        </div>
      </div>
    </>
  );
};
