"use client";

// External Imports
import clsx from "clsx";
import React, { useMemo } from "react";
import dynamic from "next/dynamic";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import Image from "next/image";
import Link from "next/link";

// Internal Imports
import { IssuesSkeleton } from "@/components/skeletons/IssuesSkeleton";
import { Column, Grid } from "@/components/layout/Grid";
import { getDefaultACFSettings } from "@/helpers/getDefaultACFSettings";
import { withSuspense } from "@/components/hoc/withSuspense";
import { usePaginationWithMaxPages } from "@/hooks/usePaginationWithMaxPages";
import { useGetUser } from "@/hooks/useGetUser";

// Type Imports
import type { IIssueLibrary } from "@/types/CustomPostTypeAcfLayouts/IssueLibrary";
import type { IIssuesFeedResponse } from "@/types/CustomPostTypeFeeds/Issue";
import type { User } from "@prisma/client";

// Lazy Load Imports
const IssueCard = dynamic(
  () => import("./IssueCard").then((mod) => mod.IssueCard),
  { ssr: true },
);
const ButtonSpinner = dynamic(
  () =>
    import("@/components/buttons/ButtonSpinner").then(
      (mod) => mod.ButtonSpinner,
    ),
  { ssr: true },
);
const CategoryButton = dynamic(
  () =>
    import("@/components/shared/CategoryButton").then(
      (mod) => mod.CategoryButton,
    ),
  { ssr: true },
);

type Props = {
  data: IIssueLibrary;
  nested?: boolean;
};

type UserResponse = {
  user?: User & { sessionType: "credentials" | "social" };
  error?: string;
};

const PER_PAGE = 20;

export const IssueLibrary = withSuspense(({ data, nested = false }: Props) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  const category = searchParams.get("category");

  const { data: userData } = useGetUser();
  const [loadedIssues, setLoadedIssues] = React.useState<
    IIssuesFeedResponse[] | []
  >([]);
  const [chosenCategory, setChosenCategory] = React.useState<
    "all" | "favorited"
  >(() => (!category ? "all" : "favorited"));
  const [replaceData, setReplaceData] = React.useState<boolean>(false);
  const isFavoritedCategory = category === "favorited";

  const favoritedIds = useMemo(() => {
    if (isFavoritedCategory) {
      setReplaceData(true);
    }
    return userData?.user?.favoritedIssues || [];
  }, [isFavoritedCategory, userData?.user?.favoritedIssues]);

  let requestUrl = isFavoritedCategory
    ? `${process.env.NEXT_PUBLIC_API_URL}/wp-json/wp/v2/issues?include=${favoritedIds?.join(",")}`
    : `${process.env.NEXT_PUBLIC_API_URL}/wp-json/wp/v2/issues`;

  const { isLoading, maxPages, page, setPage } =
    usePaginationWithMaxPages<IIssuesFeedResponse>({
      requestUrl: requestUrl,
      setGridElements: setLoadedIssues,
      perPage: PER_PAGE,
      mergeOrReplace: replaceData ? "replace" : "merge",
    });

  React.useEffect(() => {
    if (isLoading && replaceData) {
      if (chosenCategory === "favorited") {
        setPage(1);
        setLoadedIssues([]);
      }
    }

    // settingLoadedIssues before no longer loading is too soon...
    if (!isLoading) {
      if (chosenCategory === "favorited" && favoritedIds.length === 0) {
        setPage(1);
        setLoadedIssues([]); // WordPress ignores the includes parameter if empty so returns all posts....
      }

      if (chosenCategory === "all" && page === 1) {
        setLoadedIssues(data.latest_issues);
      }
    }
  }, [
    favoritedIds,
    chosenCategory,
    page,
    data.latest_issues,
    isLoading,
    setPage,
    replaceData,
  ]);

  // Default ACF Settings
  const { hide, paddings, margins, classes, id, backgroundColor, bgImgStyles } =
    getDefaultACFSettings(data);

  // Group all main layout classes here
  const combinedClasses = clsx(
    "relative",
    "acf-layout",
    "text-editor-block",
    `w-full space-y-[2.5rem] flex flex-col items-start text-pn-black-base`,
    paddings,
    margins,
    classes,
  );

  const handleCategoryChange = (category: string) => {
    // setLoadedIssues([])
    setPage(1);
    if (category === "all") {
      requestUrl = `${process.env.NEXT_PUBLIC_API_URL}/wp-json/wp/v2/issues`;
      router.push(pathname);
      setChosenCategory("all");
    } else {
      requestUrl = `${process.env.NEXT_PUBLIC_API_URL}/wp-json/wp/v2/issues?include=${favoritedIds?.join(",")}`;
      router.push(`${pathname}?category=${category}`);
      setChosenCategory("favorited");
    }
  };

  if (hide) {
    return <></>;
  }

  return (
    <div className={combinedClasses}>
      <div className={`${!nested ? "wrapper" : ""}`}>
        <div className="flex justify-between items-center max-lg:flex-col max-lg:space-y-10 max-lg:items-start">
          <h1 className="h1-bold text-pn-black-base">{data.intro_header}</h1>

          <div className="flex items-start space-x-[0.625rem]">
            <CategoryButton
              active={chosenCategory === "all"}
              onClick={() => {
                setReplaceData(true);
                handleCategoryChange("all");
              }}
            >
              All
            </CategoryButton>
            <FavoritedCategoryButton
              onClick={() => {
                setReplaceData(true);
                handleCategoryChange("favorited");
              }}
              active={chosenCategory === "favorited"}
            />
          </div>
        </div>

        <IssuesGrid
          loadedIssues={loadedIssues}
          isLoading={isLoading}
          user={userData}
        />

        <button
          className={clsx(
            `btn podcast-btn w-full mt-10 font-medium`,
            (page >= maxPages ||
              (chosenCategory === "favorited" && favoritedIds.length <= 20)) &&
              "opacity-50",
            isLoading ? "!bg-pn-black-base" : "",
          )}
          onClick={() => {
            setReplaceData(false);
            setPage((prev) => prev + 1);
          }}
          disabled={
            page >= maxPages ||
            (chosenCategory === "favorited" && favoritedIds.length <= 20)
          }
        >
          {isLoading ? <ButtonSpinner /> : "Load More"}
        </button>
      </div>
    </div>
  );
});

type IssuesGridProps = {
  loadedIssues: IIssuesFeedResponse[];
  isLoading: boolean;
  user: UserResponse | undefined;
};

const IssuesGrid = withSuspense(
  ({ loadedIssues, isLoading, user }: IssuesGridProps) => {
    if (isLoading && loadedIssues.length === 0) {
      return <IssuesSkeleton columns={5} />;
    }

    if (!isLoading && loadedIssues.length === 0) {
      return (
        <div className="mt-10 content content-flow">
          {!user?.user && (
            <p>
              Please <Link href="/login">login</Link> to view your favorite
              issues
            </p>
          )}

          {user?.user && (
            <p className="h3 font-roboto text-pn-black-base">
              You currently have no favorited issues...
            </p>
          )}
        </div>
      );
    }

    return (
      <>
        <Grid
          classes={
            "mt-10 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
          }
        >
          {loadedIssues &&
            loadedIssues.map((issue) => (
              <Column key={`${issue.id}`}>
                <IssueCard data={issue} />
              </Column>
            ))}
        </Grid>
      </>
    );
  },
  () => {
    return <IssuesSkeleton columns={5} />;
  },
);

type FavoritedCategoryButtonProps = {
  active?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const FavoritedCategoryButton = ({
  active,
  ...props
}: FavoritedCategoryButtonProps) => {
  return (
    <button
      className={clsx(
        "flex items-center justify-center h-[42px] w-[42px] group " +
          "border border-solid border-pn-black-base rounded-btn",
        active
          ? "bg-pn-black-base hover:bg-pn-black-hover"
          : "bg-pn-white-base hover:bg-pn-white-hover",
      )}
      {...props}
    >
      {active ? (
        <Image
          src="/icons/favorite.svg"
          width={18}
          height={16}
          alt="Favorite"
        />
      ) : (
        <Image
          src="/icons/favorited.svg"
          width={18}
          height={16}
          alt="Favorited"
        />
      )}
    </button>
  );
};
